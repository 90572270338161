<template>
  <div class="main_container Lister">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3 bread-crumbs">
      <div v-if="!order"
           class="col-12">
        Reglement des décomptes >
      </div>
      <div v-else
           class="col-12">
        <a href="#"
           role="button"
           @click="desactivation()">
          Reglement des décomptes>
        </a>
        Générer décaissement
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title"
               v-if="!order">
            Liste des Reglement des décomptes
          </div>
          <div class="card-body text-center title"
               v-else>
            Générer un ordre de virement
          </div>
        </div>
      </div>
    </div>
    
    <div v-if="!order"
         class="row inner-banner">
      <div class="col-3">
        <button class="btn btn-blue mr-4"
                @click="activation()"
                v-if="(user[1] == 'FER' && (user[0] == 'DAF'|| user[0]=='DCG'))||(user[1] == 'FER' && user[0] == 'ADMIN')">
          Générer Ordre de virement
        </button>
      </div>
      <div class="col">
        <router-link to="/ordre-virements"
                     custom
                     v-slot="{ href, navigate }">
          <a class="btn btn-blue"
             :href="href"
             role="button"
             @click="navigate">
            Voir Ordres de virement
          </a>
        </router-link>
      </div>
      <!-- <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div> -->
      <div class="div_left">
        <router-link :to="{ path: '/filtre' }"
                     class="btn btn-blue">
          Filtrer
        </router-link>
        <!-- <a class="btn_round">Ajouter un décompte presta. intellect.</a> -->
      </div>
      <div class="div_left">
        <!-- <a class="btn btn-blue"
           :href="baseUrl + decaissements.url"
           download>
          Exporter
        </a> -->
      </div>
    </div>
    <div v-else
         class="row inner-banner">
      <div class="col-md-4 mx-auto text-center">
        <a class="btn btn-f-blue mr-4"
           data-toggle="modal"
           data-target="#exampleModal">
          Valider
        </a>
        <a class="btn btn-f-blue"
           role="button"
           @click="desactivation(), (decaissementList = [])"> <i class="flaticon flaticon-cancel"></i> Annuler </a>
      </div>
    </div>
    <table class="table table-striped">
      <thead>
        <tr>
          <!-- Table header Filter-->
          <th scope="col"
              class="text-left th-blue">
            Numéro décompte
          </th>
          <!-- <th scope="col"
              class="text-left th-blue">
            Numéro
          </th> -->
          <th scope="col"
              class="text-left th-blue">
            Date
          </th>
          
          <th scope="col"
              class="text-left th-blue">
            Ordre de virement
          </th>
          <th scope="col"
              class="text-left th-blue">
            Marché
          </th>
          <th class="th-blue"> 
            Statut
          </th>
          <th scope="col"
              class="text-right th-blue">
            Montant
          </th>
        </tr>
      </thead>
      <tbody v-if="!loaded">
        <tr class="text-center">
          <td colspan="7">Chargement...</td>
        </tr>
      </tbody>
      <tbody v-else-if="long != 0">
        <tr v-for="(decaissement, key) in decaissements.donnees"
            :key="key">
          <td scope="row">
            <input type="checkbox"
                   name="decaissement"
                   :id="decaissement.id"
                   :value="decaissement.id"
                   v-if="order"
                   ref="listdecaiss"
                   @click="checkDecaissement(decaissement.id)" />
            <label for=""
                   class="checker">&nbsp;&nbsp;{{ decaissement.decompte.numero_decompte }}</label>
          </td>
          <td>{{ decaissement.date }}</td>
          <!-- <td>{{ decaissement.decompte.numero_decompte }}</td> -->
          <!-- Pour voir l'ordre de virement associé s'il existe-->
          <td v-if="decaissement.ordre_virement.id != null">{{ decaissement.ordre_virement.id }}</td>
          <td v-else>Aucun ordre de virement</td>

          <td>{{ decaissement.decompte.marche.libelle }}</td>
          <!-- <td>{{ splitToString (decaissement.statut) }}</td> -->
          <td>{{ decaissement.statut }}</td>
          <td class="text-right">{{ Math.round(decaissement.montant).toLocaleString() }} FCFA</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="text-center">
          <td colspan="7">Aucun décaissements à valider ou existant</td>
        </tr>
      </tbody>
    </table>
    <div class="row pagination">
      <div v-if="decaissements.pagination"
           class="col-md-2 mx-auto text-center">
        <span v-if="decaissements.pagination.precedent"
              class="badge badge-pill badge-info"
              @click="pIndex--, paging(decaissements.pagination.precedent)"> &#60; </span>
        {{ pIndex }}
        <span v-if="decaissements.pagination.suivant"
              class="badge badge-pill badge-info"
              @click="pIndex++, paging(decaissements.pagination.suivant)"> &#62; </span>
      </div>
    </div>
    <!-- Modal  -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <form class="form_default">
              <div class="form-group">
                <label for="">Banques</label>
                <select class="form-control"
                        v-model="compte"
                        v-if="banks.length>0">
                  <option value="">Choisissez une banque</option>
                  <optgroup v-for="(banque,bakey) in banks"
                            :key="bakey"
                            :label="banque.denomination + '(' + banque.sigle+')'">
                    <option v-for="(compte, cokey) in banque.compte_bancaires"
                            :key="cokey"
                            :value="compte.id">{{compte.numero}}</option>
                  </optgroup>
                </select>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                    aria-label="Close">Non</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="send()">Oui</button>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal -->
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.tab-pane {
  padding-top: 5vh;
}
.inner-banner {
  margin-bottom: 5vh;
}
.table-striped tbody td,
.table-striped thead th{
  font-size: 0.48em;
}
label.checker{
  font-size: 1em;
}

</style>
<script>
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
import ListFilter from "@/components/shared/Filter"
export default {
  name: "DcmtList",
  components: {
    ListFilter,
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    filtre:[],
    chmp:[
      {
        valeur:"id",
        type:"numeric",
        table:"decaissements",
        libelle:"Numéro",
      },
      {
        valeur:"date",
        type:"date",
        table:"decaissements",
        libelle:"date",
      },
      {
        valeur:"id",
        type:"numeric",
        table:"decomptes",
        libelle:"Code décompte",
      },
      {
        valeur:"id",
        type:"numeric",
        table:"ordre_virements",
        libelle:"Ordre de virement",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"marches",
        libelle:"Marché",
      },
      {
        valeur:"statut",
        type:"caractere",
        table:"decaissements",
        libelle:"Statut",
      },
      {
        valeur:"montant_facture_decompte",
        type:"numeric",
        table:"decomptes",
        libelle:"Montant",
      }
    ],
    baseUrl: process.env.VUE_APP_UPLOAD,
    order: false,
    decaissData: {
      libelle: null,
      order: null,
      tri: null,
      en_attente: 1
    },
    banks:[],
    compte:"",
    user:"",
    decaissementList: [],
    pIndex:1,
    long: "",
    loaded: false,
    checked: false
  }),
  watch: {
    singleOrdres() {
      if (this.singleOrdres) {
        this.notif.message = this.singleOrdres
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setOrdres("")
          }.bind(this),
          4000
        )
        this.$router.push({ name: "Virement" })
      } else {
        this.notif.message = this.virementsErrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    },
    decaissements() {
      if (this.decaissements.donnees) {
        this.loaded = true
        this.long = this.decaissements.donnees.length
      }
    },
    banques(){
      if (this.banques.donnees) {
        this.banks=[]
        this.banques.donnees.forEach(banque => {
          if (banque.compte_bancaires.length>0) {
            this.banks.push(banque)
          }
        })
      }
    }
  },
  mounted() {
    localStorage.setItem("module","decaissements")

    this.Decaissements()
    this.loadBanques()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed: {
    ...mapGetters(["decaissements", "singleDecaissements","decomptes", "errors", "singleOrdres", "virementsErrors","banques"])
  },
  methods: {
    ...mapActions(["Decaissements", "valDecaissements", "saveOrdre", "pageDecaissements","loadBanques"]),
    ...mapMutations(["setOrdres", "setErrors", "setDecaissements"]),
    launchDetail: function(decaissement) {
      this.$router.push({ name: "DDetails", params: { decaissement: decaissement } })
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.pageDecaissements(url)
    },
    // filtrer() {
    //   this.loaded = false
    //   this.long = null
    //   this.setDecaissements("")
    //   this.Decaissements(this.filtre)
    // },
    arrayRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele != value
      })
    },
    activation: function() {
      this.order = true
      this.loaded = false
      this.setDecaissements("")
      this.valDecaissements(this.decaissData)
    },
    desactivation: function() {
      this.order = false
      this.loaded = false
      this.setDecaissements("")
      this.Decaissements()
    },
    checkDecaissement(id) {
      this.$refs["listdecaiss"].forEach(list => {
        if (list.checked && list.value == id) {
          if (this.decaissementList.length == 0) {
            this.decaissementList.push(id)
          } else {
            this.decaissementList.forEach(dec => {
              if (dec != id) {
                this.decaissementList.push(id)
              }
            })
          }
        } else {
          if (!list.checked && list.value == id) {
            this.decaissementList = this.arrayRemove(this.decaissementList, id)
          }
        }
      })
    },
    send() {
      if (this.decaissementList.length != 0) {
        this.saveOrdre({ decaissements: this.decaissementList, compte: this.compte })
        // this.decaissementList = []
        // this.Decaissements(this.decaissData)
      }
    },
    filtrer() {
      this.loaded = false
      this.setDecaissements("")
      if (this.order) {
        this.valDecaissements(this.filtre)
      } else {
        this.decaissData.en_attente = ""
        this.Decaissements(this.filtre)
      }
    },
    restoring() {
      this.loaded = false
      // this.long = null
      this.setDecaissements("")
      this.decaissData = { libelle: null, order: null, tri: null, en_attente: 1 }
      if (this.order) {
        this.valDecaissements(this.decaissData)
      } else {
        this.Decaissements()
      }
    },
    splitToString(chaine) {
      return chaine.replaceAll("_", " ")
    }
  }
}
</script>
